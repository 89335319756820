
import React from 'react';

import Layout from '../components/Layout';
import headingimg from '../assets/images/headingimg.png';
import config from '../../config';
import Footer from '../components/Footer';

const IndexPage = () => (
    <Layout>
    <header id="header">
    <div className="content">
      <div className="image-header">
        <img src={headingimg} alt={config.heading} />
        {/* <h1>www.ddns.eus</h1> */}
      </div>
  
      <p>{config.subHeading}</p>
      <h2>You are not authorized to access this share.</h2>
      <p>
      Please contact to whomever gave you the share link for a new valid link.
      </p>
    </div>
  </header>
  
  <Footer />
  </Layout>
);

export default IndexPage;
